import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import BlogAppBar from '../components/BlogAppBar'
import Footer from '../components/Footer'
import './index.scss'
import { Grid, Typography } from '@material-ui/core'
import Img from 'gatsby-image'

export default function Template({ data }) {
  const { markdownRemark: post } = data
  return (
    <div className="blog-post-container">
      <Helmet title={`Upload My Stock - ${post.frontmatter.title}`}>
        <meta name="Description" content={post.frontmatter.subtitle} />
      </Helmet>
      <BlogAppBar />
      <div className="blog-post">
        <Typography variant="h1" gutterBottom>
          {post.frontmatter.title}
        </Typography>
        <Img
          className="post-image"
          fixed={post.frontmatter.featuredImage.childImageSharp.fixed}
        />
        <Grid container>
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle2">
              {post.frontmatter.author}
            </Typography>
            <Typography variant="overline">
              Published {post.frontmatter.date}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div
              className="blog-content"
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            {(post.frontmatter.sideBar || []).map(content => (
              <div className="side-bar-content" style={{ top: content.spacer }}>
                <Typography variant="overline">PRO TIP</Typography>
                <svg
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 54 54"
                  className="tip-icon"
                >
                  <path d="M27,8c-9.374,0-17,7.626-17,17c0,7.112,4.391,13.412,11,15.9V50c0,0.553,0.447,1,1,1h1v2c0,0.553,0.447,1,1,1h6 c0.553,0,1-0.447,1-1v-2h1c0.553,0,1-0.447,1-1v-9.1c6.609-2.488,11-8.788,11-15.9C44,15.626,36.374,8,27,8z M30,49 c-0.553,0-1,0.447-1,1v2h-4v-2c0-0.553-0.447-1-1-1h-1v-5h8v5H30z M31.688,39.242C31.277,39.377,31,39.761,31,40.192V42h-8v-1.808 c0-0.432-0.277-0.815-0.688-0.95C16.145,37.214,12,31.49,12,25c0-8.271,6.729-15,15-15s15,6.729,15,15 C42,31.49,37.855,37.214,31.688,39.242z" />
                  <path d="M27,6c0.553,0,1-0.447,1-1V1c0-0.553-0.447-1-1-1s-1,0.447-1,1v4C26,5.553,26.447,6,27,6z" />
                  <path d="M51,24h-4c-0.553,0-1,0.447-1,1s0.447,1,1,1h4c0.553,0,1-0.447,1-1S51.553,24,51,24z" />
                  <path d="M7,24H3c-0.553,0-1,0.447-1,1s0.447,1,1,1h4c0.553,0,1-0.447,1-1S7.553,24,7,24z" />
                  <path d="M43.264,7.322l-2.828,2.828c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,0.451,0.293,0.707,0.293 s0.512-0.098,0.707-0.293l2.828-2.828c0.391-0.391,0.391-1.023,0-1.414S43.654,6.932,43.264,7.322z" />
                  <path d="M12.15,38.436l-2.828,2.828c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,0.451,0.293,0.707,0.293 s0.512-0.098,0.707-0.293l2.828-2.828c0.391-0.391,0.391-1.023,0-1.414S12.541,38.045,12.15,38.436z" />
                  <path d="M41.85,38.436c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l2.828,2.828c0.195,0.195,0.451,0.293,0.707,0.293 s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414L41.85,38.436z" />
                  <path d="M12.15,11.564c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414l-2.828-2.828 c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414L12.15,11.564z" />
                  <path d="M27,13c-6.617,0-12,5.383-12,12c0,0.553,0.447,1,1,1s1-0.447,1-1c0-5.514,4.486-10,10-10c0.553,0,1-0.447,1-1 S27.553,13,27,13z" />
                </svg>
                <Typography variant="h6" gutterBottom>
                  {content.title}
                </Typography>
                <Typography variant="body2">{content.subtitle}</Typography>
              </div>
            ))}
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  )
}
export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        subtitle
        author
        sideBar {
          title
          subtitle
          spacer
        }
        date(formatString: "MMMM DD, YYYY")
        path
        featuredImage {
          childImageSharp {
            fixed(height: 450) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        topArticle
        featured
      }
    }
  }
`
